import React, { useContext, useState } from 'react';

function Znamkovani() {
  return (
    <section id="materialy" className="section znamkovani">
      <h2>Známkování</h2>
      <div className="bold"><p>Cílem semináře není vám dát známku, ale reálně vás něco naučit a inspirovat.</p></div>
      <p>Nicméně, nějaké známky vám musím dát.</p>
      <p>Klasifikace bude rozdělena do dvou hlavních částí: <b>úkoly</b> a <b>zkouška</b>. Každý týden vám budu zadávat úkoly, na jejichž splnění budete mít k dispozici 2 týdny. Za každý úkol obdržíte body. Zkouška se uskuteční ke konci pololetí a bude pokrývat veškeré probírané učivo. Body získané za zkoušku se připočítají k vašemu celkovému skóre. Vaše konečná známka bude vypočtena na základě procentuálního vyjádření získaných bodů z možného celkového počtu bodů.</p>
      <p>1 ... 80–100 %</p>
      <p>2 ... 60–79,99 %</p>
      <p>3 ... 45–59,99 %</p>
      <p>4 ... pod 45 %</p>

      <p><b>Pokud ale zvládnete udělat všechny úkoly, dostanete automaticky známku 1 a nemusíte psát zkoušku.</b></p> 
      <p>Navíc za 3 každé vypracované úkoly, alespoň s nadpolovičním počtem bodů z každého, vám napíšu jedničku s váhou 1.</p> 
      <p>Budu se snažit být vám nápomocný při plnění úkolů. Stanovené termíny jsou konečné, ale pokud se ozvete s předstihem, rád termín prodloužím. Na všem je možné se domluvit. Pokud budete mít jakékoliv nejasnosti, neváhejte se na mě obrátit, jsem zde, abych vám pomohl.</p>
      <p><b>Nebudu však tolerovat opisování.</b> To neznamená, že nemůžete na úkolech spolupracovat a diskutovat o jejich řešení. Jen platí pravidlo, že kód (úkol) píše každý sám. Ne společně.</p>
    </section>
  );
}
export default Znamkovani;
